/* eslint-disable @typescript-eslint/no-unused-vars */
// import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import usePagination from '@mui/material/usePagination';

type ModalProps = {
  total?: any;
  DefaultPerPage?: any;
  setfn?: any;
  dashBoard?: any;
};

const Paginate: React.FC<ModalProps> = ({ setfn, total, DefaultPerPage, dashBoard }) => {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [Count, setCount] = useState(0);
  const [Changecount, setChangecount] = useState<number | null>(null);
  const BtnhandleChange = (take: any, page: number) => {
    if (Changecount) {
      // console.log(take,'handlcjhng',Changecount);
      const skiped: number = Changecount * (+page - 1);
      setfn(skiped, Changecount ? Changecount : 0);
    }
    // rowPerPage(event.target.value, event.target.value);
  };

  const { items } = usePagination({
    count: Count,
    onChange: BtnhandleChange,
  });

  const [pgn, setpgn] = useState(1);

  // const handleClick = (event: any) => {
  //   const currentPage = event.page ? event.page : 1;
  //   // console.log(`Clicked page number: ${currentPage}`);

  //   const skip = (currentPage - 1) * DefaultPerPage;
  //   const take = DefaultPerPage;
  //   setpgn(currentPage);
  //   setfn(skip, take);
  //   // ... Perform other actions based on the current page sk:number,tk:number
  // };

  const handleChange = (event: any) => {
    // console.log(event.target.value,'handlcjhng');
    setChangecount(event.target.value);
    setfn(0, event.target.value);
    // rowPerPage(event.target.value, event.target.value);
  };

  const rowPerPage = (t: any, p: any) => {
    const i = Math.ceil(t / p);
    return Array(i)
      .fill(i ? i : 10)
      .map((e, ie) => (ie + 1) * p);
  };

  // new pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useLayoutEffect(() => {
    setCount(Math.ceil(total / DefaultPerPage) <= 1 ? 1 : Math.ceil(total / DefaultPerPage));
    setChangecount(DefaultPerPage);
  }, []);

  useEffect(() => {
    Changecount
      ? setCount(Math.ceil(total / Changecount) <= 1 ? 1 : Math.ceil(total / Changecount))
      : setCount(Math.ceil(total / DefaultPerPage) <= 1 ? 1 : Math.ceil(total / DefaultPerPage));
  }, [total, DefaultPerPage, Changecount]);

  // useEffect(() => {
  //   console.log(items);
  //   if(type === 'previous'){
  //     const pgnum:any = items.find((er:any) => er && er.type=== 'page' && er.selected === true);
  //     console.log(pgnum,'pgnum');
  //     pgnum && pgnum.type === 'page' && pgnum.page >= 0 && BtnhandleChange(pgnum.page - 1);
  //   }
  //   if(type === 'next'){
  //     const pgnum:any = items.find((er:any) => er && er.type=== 'page' && er.selected === true);
  //     console.log(pgnum,'pgnum');
  //     pgnum && pgnum.type === 'page' && pgnum.page > 0 && BtnhandleChange(pgnum.page + 1);
  //   }
  // },[items]);

  // useEffect(() => {
  //   setCount(Math.ceil(total / DefaultPerPage) <= 1 ? 1 : Math.ceil(total / DefaultPerPage));
  //   console.log(total / DefaultPerPage);
  //   console.log(Count);
  //   console.log(rowPerPage, 'rowPerPage');
  // }, [total, DefaultPerPage, rowsPerPage,rowPerPage]);

  return (
    <Grid container className={dashBoard ? 'mt-15' : 'mt-35'} alignItems='center'>
      <Grid item md={4} sm={12} xs={12} className='d-flex-a'>
        <Typography component={'div'} className='d-flex-a'>
          <Typography className='show-text'>Rows per page </Typography>
          <FormControl sx={{ minHeight: 42, minWidth: 70 }} size='small' className='page-dropbox'>
            <Select
              style={{ fontSize: '14px' }}
              labelId='demo-select-small-label'
              id='demo-select-small'
              IconComponent={KeyboardArrowDownIcon}
              defaultValue={DefaultPerPage ? DefaultPerPage : 0}
              onChange={handleChange}>
              {rowPerPage(total, DefaultPerPage).map((ef: any, ig: any) => {
                return (
                  <MenuItem value={ef} key={ef}>
                    {ef}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Typography>
      </Grid>
      {/* <Grid item md={8} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a flex-end pagination'>
          <Pagination
            count={Math.ceil(Number(total / DefaultPerPage))}
            renderItem={(item) => {
              // console.log(item, 'item');
              return (
                <span onClick={() => handleClick(item)}>
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                </span>
              );
            }}
          />
        </Typography>
      </Grid> */}
      <Grid item md={8} sm={12} xs={12}>
        {/* <Typography component={'div'} className='d-flex-a flex-end pagination'>
          <TablePagination
            component='div'
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Typography> */}
        {/* <Stack spacing={2}> */}
        {/* <Pagination
          count={Count}
          renderItem={(item) => (
            <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
          )}
        /> */}
        <Typography component={'div'} className='d-flex-a flex-end pagination'>
          <nav>
            <List style={{ display: 'flex' }}>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;
                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = '…';
                } else if (type === 'page') {
                  children = (
                    <button
                      type='button'
                      style={{
                        fontWeight: selected ? 'bold' : undefined,
                      }}
                      {...item}
                      // onClick={(e) => (items.length <= 3 ? '' : BtnhandleChange(page))}
                      // disabled={items.length <= 3 ? true : false}
                    >
                      {page}
                    </button>
                  );
                } else {
                  children = (
                    <button
                      type='button'
                      {...item}
                      style={{ whiteSpace: 'nowrap' }}
                      // onClick={(e) => {
                      //   const pgnum: any = items.find(
                      //     (er: any) => er && er.type === 'page' && er.selected === true,
                      //   );
                      //   // console.log(pgnum, 'pgnum');
                      //   if (type === 'previous') {
                      //     pgnum &&
                      //       pgnum.type === 'page' &&
                      //       pgnum.page >= 0 &&
                      //       BtnhandleChange(pgnum.page - 1);
                      //   }
                      //   if (type === 'next') {
                      //     pgnum &&
                      //       pgnum.type === 'page' &&
                      //       pgnum.page >= 0 &&
                      //       BtnhandleChange(pgnum.page + 1);
                      //   }
                      //   // onClick(e);
                      // }}
                    >
                      {type === 'previous' ? 'Prev' : type === 'next' ? 'Next' : ''}
                    </button>
                  );
                }

                return <li key={index}>{children}</li>;
              })}
            </List>
          </nav>
        </Typography>
        {/* </Stack> */}
      </Grid>
    </Grid>
  );
};
export default Paginate;
