import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
// import Loader from 'components/loader';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import React from 'react';

export const DummyDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [Ld, setLd] = React.useState(true);
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={'/dashboard'}>
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Dashboard
              </Link>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>Dashboard</Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'></Typography>
          </Typography>
        </Grid>
      </Grid>
      {/* {Ld && <Loader />} */}
    </>
  );
};
