/* eslint-disable no-duplicate-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingNormal from 'assets/png/settingnormal.svg';
import SettingActive from 'assets/png/settingactive.svg';
import ClientIcon from 'assets/png/wclient.svg';
import ClientActiveIcon from 'assets/png/wclient-active.svg';
import MenuLogo from 'assets/png/barcollapse.svg';
import TrackingIcon from 'assets/png/wtracking.svg';
import PerformIcon from 'assets/png/wperformance.svg';
// import NoteIcon from 'assets/png/note.svg';
import Fulllogo from 'assets/png/cthiring_new_logo.png';
import ProfileIcon from 'assets/png/myprofile.svg';
import KeyIcon from 'assets/png/key.svg';
import CloseIcon from 'assets/png/close.svg';
import LogoutIcon from 'assets/png/logout.svg';
// import Light from 'assets/png/Light.svg';
// import Dark from 'assets/png/dark.svg';
import { Avatar, Button, Box, Modal, Popover } from '@mui/material';
import Footer from '../Common/footer';
import {
  AdminPrivateRoutes,
  AdminPrivateRoutes as PrivateRoutesDirectives,
} from 'routes/routeConstans';
import { Link, To, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteData, getAllListData } from 'services/CommonServices';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, SETTING_ENDPOINT, VERSIONHISTORYENDPOINT } from 'utils/Constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logout } from '../../store/actions/UserAction';
import ImageWithAuth from 'components/ProfileImage';
import { SIDE_MENUS } from 'store/types/UserTypes';
import Storage from 'utils/Storage';
import _ from 'lodash';
import * as R from 'ramda';
import DashboardHoverActive from 'assets/png/dashboardHoverActive.svg';
import DashboardHoverInactive from 'assets/png/dashboardHoverInactive.svg';
import PositionHoverActive from 'assets/png/wpositionorange.svg';
import PositionHoverInctive from 'assets/png/wposition.svg';
import ResumeHoverActive from 'assets/png/resumeHoverActive.svg';
import ResumeHoverInactive from 'assets/png/wresume.svg';
import InterviewHoverInactive from 'assets/png/interviewHoverInactive.svg';
import InterviewHoverActive from 'assets/png/interviewHoverActive.svg';
import BillingHoverActive from 'assets/png/billingHoverActive.svg';
import BillingsHoverInactive from 'assets/png/billingsHoverInactive.svg';
import LeaveHoverActive from 'assets/png/leaveHoverActive.svg';
import LeavesHoverInactive from 'assets/png/leavesHoverInactive.svg';
import PlanHoverInactive from 'assets/png/wplan.svg';
import PlanHoverActive from 'assets/png/planHoverActive.svg';
import EventsHoverActive from 'assets/png/eventsHoverActive.svg';
import EventsHoverInactive from 'assets/png/event.svg';
import CvbankHoverActive from 'assets/png/cvbankHoverActive.svg';
import CvbankHoverInactive from 'assets/png/cvbankHoverInactive.svg';
import ReferenceHoverActive from 'assets/png/referenceHoverAvtive.svg';
import ReferenceHoverInactive from 'assets/png/referenceHoverInactive.svg';
import MailboxHoverActive from 'assets/png/mailboxHoverActive.svg';
import PaymentMailInactive from 'assets/png/paymentMail.svg';
import PaymentMailActive from 'assets/png/paymentMailactive.svg';
import ClientAgreementInactive from 'assets/png/clientAgree.svg';
import ClientAgreementActive from 'assets/png/clientAgreeActive.svg';
import MailboxHoverInactive from 'assets/png/mailboxHoverInactive.svg';
import ReportsHoverActive from 'assets/png/reportsHoverActive.svg';
import ReportsHoverInactive from 'assets/png/wreport.svg';

const drawerWidth = 238;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '407px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#2E162E',

  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#2E162E',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const loginimgopen = (): CSSObject => ({
  display: 'flex',
  paddingRight: 15,
  paddingLeft: 15,
});

const loginimgclose = (): CSSObject => ({
  display: 'none',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      '& .sidebaravatar': loginimgopen(),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .sidebaravatar': loginimgclose(),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  paddingLeft: '20px !important',
  height: 72,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  height: 72,
  justifyContent: 'center',
  background: '#FFF',
  boxShadow: 'unset !important',
  borderBottom: '1px solid #E8E8E8',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type LayoutConfigsProps = {
  path: string;
  children: any;
};

const drawerStyle = {
  text: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontWeight: '600',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconMenu: {
    height: 22,
    width: 22,
  },
  zastiIcon: {
    height: 26,
    width: 75,
  },
  listButton: {
    borderRadius: '4px',
    margin: '15px',
  },
  versionText: {
    position: 'absolute',
    right: 15,
    fontSize: '12px !important',
    lineHeight: '20px !important',
    fontWeight: '400',
  },
  organizationLogo: {
    width: 68,
    height: 55,
  },
  nameLogo: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: '#28A9E1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px !important',
    lineHeight: '24px !important',
    fontWeight: '500',
  },
  nametext: {
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontWeight: '500',
    color: '#111',
    marginRight: '10px',
  },
  profilePng: {
    height: 20,
    width: 24,
  },
};

export enum extraLinkUrlType {
  myPlan = 'myPlan',
  settings = 'settings',
}

const AdminPrivateLayout = (props: LayoutConfigsProps) => {
  const { SETTINGS } = PrivateRoutesDirectives;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [version, setVersion] = React.useState('');
  const [settingModule, setSettingModule] = React.useState([]);
  const [adminopen, setAdminOpen] = React.useState(false);
  const handleOpen = () => setAdminOpen(true);
  const handleClose = () => setAdminOpen(false);
  const { userInfo: userDetail, menuList: screenList }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [myPlananchorEl, setMyPlanAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [leaveAnchorEl, setLeaveAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const myPlanHandleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setMyPlanAnchorEl(event.currentTarget);
  };

  const myPlanHandleClose = () => {
    setMyPlanAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleLeaveClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setLeaveAnchorEl(event.currentTarget);
  };

  const handleLeaveClose1 = () => {
    setLeaveAnchorEl(null);
  };
  const myPlanOpen = Boolean(myPlananchorEl);
  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popover' : undefined;
  const myPlanId = myPlanOpen ? 'simple-popover' : undefined;

  const leaveOpen1 = Boolean(leaveAnchorEl);
  const leaveId = leaveOpen1 ? 'simple-popover' : undefined;

  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);

  const handleprofileClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const handleprofileClose = () => {
    setPAnchorEl(null);
  };

  const popen = Boolean(panchorEl);
  const pid = popen ? 'simple-popover' : undefined;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    setPAnchorEl(null);
    setAnchorEl(null);
    setLeaveAnchorEl(null);
    navigate(link);
  };
  const logoutsession = () => {
    logout(dispatch);
    deleteData(userDetail.refreshTokenId, MASTER_ENDPOINT.Logout)
      .then((res) => {
        Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
        toast.success('Logged out successfully', alertOptions);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
  React.useEffect(() => {
    getAllListData(
      `${SETTING_ENDPOINT.moduleScreenList}?roleId=${userDetail.roleId}&access=true&order=asc&sortby=order&skip&take`,
      true,
    )
      .then((res: any) => {
        if (!screenList || screenList.length === 0 || !_.isEqual(res?.data, screenList)) {
          dispatch({
            type: SIDE_MENUS,
            payload: res?.data,
          });
          Storage.setItem(Storage.KEYS.MENUS, JSON.stringify(res?.data));
        }
      })
      .catch((e: any) => {
        // console.log(e);
        // setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(screenList)) {
      const settingData = screenList.find((e: any) => e.moduleName === 'Settings');
      setSettingModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [screenList]);
  React.useEffect(() => {
    getAllListData(
      `${VERSIONHISTORYENDPOINT.versionHistory}?organizationId=&order=desc&sortby=createdAt`,
      true,
    )
      .then((resp: any) => {
        if (resp && !_.isEmpty(resp.data)) {
          setVersion(resp?.data[0]?.versionId);
        }
      })
      .catch(() => {
        // setLd(false);
      });
  }, []);

  return (
    <Box className='light-theme' sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar className='custom-toolbar'>
          <Typography component={'div'} className='d-flex-a flex-sb w-100'>
            <Typography component={'div'} className='d-flex-a'>
              <Typography
                className='hr adminextra'
                sx={{ ...(open && { display: 'none' }) }}
                component={'p'}>
                <img src={Fulllogo} alt='SimplifiedHR' />
                <IconButton
                  className={'collapse-btn'}
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  sx={{
                    ...(open && { display: 'none' }),
                  }}>
                  <Avatar
                    sx={drawerStyle.iconMenu}
                    variant='square'
                    alt='ct-hiring'
                    src={MenuLogo}
                  />
                </IconButton>
              </Typography>
              {/* <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                sx={{ marginLeft: '66px', width: '320px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              {/* <img src={NoteIcon} alt='notification' /> */}
              <Typography component={'div'} className='d-flex-a'>
                <Typography
                  sx={{ textTransform: 'capitalize' }}
                  className='header-name'>{`${userDetail.firstName} ${userDetail.lastName}`}</Typography>
                <Typography component={'div'}>
                  {userDetail.imageId ? (
                    <Typography className='wh-32' onClick={handleprofileClick}>
                      <ImageWithAuth className='d-avator' id={userDetail.imageId} alt={''} />
                    </Typography>
                  ) : (
                    <Typography className='d-avator d-flex-ja' onClick={handleprofileClick}>
                      {userDetail?.firstName.toUpperCase().trim().split('')[0] +
                        userDetail?.lastName.toUpperCase().trim().split('')[0]}
                    </Typography>
                  )}
                </Typography>
              </Typography>
              <Popover
                className='profile-drop'
                id={pid}
                open={popen}
                anchorEl={panchorEl}
                onClose={handleprofileClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <Typography component={'div'} className='menu-box'>
                  <Typography component={'div'} className='np-box d-flex-a'>
                    <Typography component={'div'}>
                      {userDetail.imageId ? (
                        <Typography className='wh-32' onClick={handleprofileClick}>
                          <ImageWithAuth className='d-avator' id={userDetail.imageId} alt={''} />
                        </Typography>
                      ) : (
                        <Typography className='d-avator d-flex-ja' onClick={handleprofileClick}>
                          {userDetail?.firstName.toUpperCase().trim().split('')[0] +
                            userDetail?.lastName.toUpperCase().trim().split('')[0]}
                        </Typography>
                      )}
                    </Typography>
                    <Typography component={'div'} className='pl-10'>
                      <Typography
                        className='np-name'
                        sx={{
                          textTransform: 'capitalize',
                        }}>{`${userDetail.firstName} ${userDetail.lastName}`}</Typography>
                      <Typography className='np-role'>
                        {userDetail?.roleId === 15
                          ? 'Administrator'
                          : userDetail?.roleData.name
                            ? userDetail?.roleData.name
                            : ''}
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography className='menu-item' onClick={() => gotoNavigate('/profile')}>
                    <img src={ProfileIcon} alt='' />
                    Profile
                  </Typography>
                  <Typography
                    className='menu-item'
                    onClick={() => gotoNavigate('/change-password')}>
                    <img src={KeyIcon} alt='' />
                    Change Password
                  </Typography>
                  <Typography className='menu-item' onClick={handleOpen}>
                    <img src={LogoutIcon} alt='' />
                    Logout
                  </Typography>
                  <Modal
                    open={adminopen}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <Box className='logout-modal'>
                      <Typography className='text-right'>
                        <img src={CloseIcon} alt='close' onClick={handleClose} />
                      </Typography>
                      <Typography className='l-title'>Logout</Typography>
                      <Typography className='l-info'>Are you sure you want to logout?</Typography>
                      <Typography className='d-flex-ja'>
                        <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button className='l-save-btn' onClick={logoutsession}>
                          Logout
                        </Button>
                      </Typography>
                    </Box>
                  </Modal>
                </Typography>
              </Popover>
            </Typography>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open} className={open ? 'normalside' : 'smallside'}>
        <DrawerHeader className='draw-header'>
          {!open ? (
            <></>
          ) : (
            <>
              <Typography className='headerlogo-text d-flex-a'>
                <img src={Fulllogo} alt='SimplifiedHR' />
              </Typography>
              <IconButton
                onClick={handleDrawerClose}
                // sx={{ ...(open ? { display: 'block' } : { display: 'none' }) }}
                className='collapse-btn'>
                {theme.direction === 'ltr' ? (
                  <Avatar sx={drawerStyle.iconMenu} variant='square' alt='Zasti' src={MenuLogo} />
                ) : (
                  <></>
                )}
              </IconButton>
            </>
          )}
        </DrawerHeader>
        <Divider className='smallborder' />
        <List>
          {/* Dashboard */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.DASHBOARD} className={'sidebarLink'}>
              {/* {R.findIndex(R.propEq('Dashboard', 'moduleName'))(screenList ? screenList : []) !==
                -1 && ( */}
              <ListItemButton
                className={
                  location && location.pathname.includes('dashboard') ? 'sideBarActive' : ''
                }
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='Companies'
                    src={
                      location && location.pathname.includes('dashboard')
                        ? DashboardHoverActive
                        : DashboardHoverInactive
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Dashboard'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
              {/* )} */}
            </Link>
          </ListItem>

          {/* Clients */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.CLIENTS.CLIENTVIEW} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Client', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('clients') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('clients')
                          ? ClientActiveIcon
                          : ClientIcon
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Clients'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Position */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            {R.findIndex(R.propEq('Position', 'moduleName'))(screenList ? screenList : []) !==
              -1 && (
              <Link to={AdminPrivateRoutes.POSITIONS.POSITIONSVIEW} className={'sidebarLink'}>
                <ListItemButton
                  className={
                    location && location.pathname.includes('positions') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('positions')
                          ? PositionHoverActive
                          : PositionHoverInctive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Positions'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            )}
          </ListItem>

          {/* Resume */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.RESUMES.RESUMESLIST} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Resume', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('resumes') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='RESUMES'
                      src={
                        location && location.pathname.includes('resumes')
                          ? ResumeHoverActive
                          : ResumeHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Resumes'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Interviews */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.INTERVIEW.INTERVIEWLIST} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Interview', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('interviews') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='INTERVIEW'
                      src={
                        location && location.pathname.includes('interviews')
                          ? InterviewHoverActive
                          : InterviewHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Interviews'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Billing */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.BILLING.BILLINGLIST} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Billing', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('billing') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='billing'
                      src={
                        location && location.pathname.includes('billing')
                          ? BillingHoverActive
                          : BillingsHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Billing'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Leaves */}
          {/* <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'}>
              {R.findIndex(R.propEq('My Leaves', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('leaves')
                          ? LeaveHoverActive
                          : LeavesHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Leaves'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem> */}

          {/* My Plan */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'} onClick={myPlanHandleClick}>
              {(R.findIndex(R.propEq('My Plan', 'moduleName'))(screenList ? screenList : []) !==
                -1 ||
                R.findIndex(R.propEq('My Leaves', 'moduleName'))(screenList ? screenList : []) !==
                  -1) && (
                <ListItemButton
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}
                  className={
                    (location && location.pathname.includes('myplan')) ||
                    (location && location.pathname.includes('myleave')) ||
                    myPlanOpen
                      ? 'sideBarActive'
                      : ''
                  }>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        (location && location.pathname.includes('myplan')) ||
                        (location && location.pathname.includes('myleave')) ||
                        myPlanOpen
                          ? PlanHoverActive
                          : PlanHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'My Plan'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Events */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.MyEvents.EventsList} className={'sidebarLink'}>
              {R.findIndex(R.propEq('My Events', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('events') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='events'
                      src={
                        location && location.pathname.includes('events')
                          ? EventsHoverActive
                          : EventsHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Events'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* CV Bank  */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.CvBank.CvBankDetails} className={'sidebarLink'}>
              {R.findIndex(R.propEq('CV Bank', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('CvBank') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('CvBank')
                          ? CvbankHoverActive
                          : CvbankHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'CV Bank'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* References */}
          {/* <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Add References', 'moduleName'))(
                screenList ? screenList : [],
              ) !== -1 && (
                <ListItemButton
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('references')
                          ? ReferenceHoverActive
                          : ReferenceHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'References'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem> */}

          {/* Mail Box  */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.MAILBOX.MAILBOXLIST} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Mail BOX', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('mail-box') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('mail-box')
                          ? MailboxHoverActive
                          : MailboxHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Mail Box'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Payment Mail */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={AdminPrivateRoutes.PAYMENTMAIL.PAYMENTMAILLIST} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Payment Mail', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('payment-mail') ? 'sideBarActive' : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('payment-mail')
                          ? PaymentMailActive
                          : PaymentMailInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Payment Mail'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Client Agreement */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link
              to={AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTLIST}
              className={'sidebarLink'}>
              {R.findIndex(R.propEq('Client Agreement', 'moduleName'))(
                screenList ? screenList : [],
              ) !== -1 && (
                <ListItemButton
                  className={
                    location && location.pathname.includes('client-agreement')
                      ? 'sideBarActive'
                      : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('client-agreement')
                          ? ClientAgreementActive
                          : ClientAgreementInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Client Agreement'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'}>
              <ListItemButton
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar sx={drawerStyle.icon} variant='square' alt='ADMINS' src={TrackingIcon} />
                </ListItemIcon>
                 <ListItemText
                  primary={'User Tracking'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                /> 
              </ListItemButton>
            </Link>
          </ListItem> */}

          {/* Reports */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'}>
              {R.findIndex(R.propEq('Reports', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        location && location.pathname.includes('reports')
                          ? ReportsHoverActive
                          : ReportsHoverInactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Reports'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
          </ListItem>

          {/* Settings */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={''} className={'sidebarLink'} aria-describedby={id} onClick={handleClick}>
              {R.findIndex(R.propEq('Settings', 'moduleName'))(screenList ? screenList : []) !==
                -1 && (
                <ListItemButton
                  className={
                    (location && location.pathname.includes('settings')) || open1
                      ? 'sideBarActive'
                      : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='ADMINS'
                      src={
                        (location && location.pathname.includes('settings')) || open1
                          ? SettingActive
                          : SettingNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Settings'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              )}
            </Link>
            <Popover
              className='submenu-pop'
              id={id}
              open={open1}
              anchorEl={anchorEl}
              onClose={handleClose1}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <Typography component={'div'} className='submenu-box'>
                {R.findIndex(R.propEq('Product Admin', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/product-administrator')}>
                    Product Administrator
                  </Typography>
                )}

                {R.findIndex(R.propEq('Daily Login Details', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/dailylogindetails')}>
                    Daily Login Details
                  </Typography>
                )}

                {R.findIndex(R.propEq('Industry', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/industry')}>
                    Industry
                  </Typography>
                )}

                {R.findIndex(R.propEq('Functional Area', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/functional-area')}>
                    Functional Area
                  </Typography>
                )}

                {R.findIndex(R.propEq('Qualification', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/qualification')}>
                    Qualification
                  </Typography>
                )}

                {R.findIndex(R.propEq('Designation', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() =>
                      gotoNavigate(AdminPrivateRoutes.SETTINGS.Designation.DesignationLIST)
                    }>
                    Designation
                  </Typography>
                )}

                {R.findIndex(R.propEq('Branches', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/branches')}>
                    Branches
                  </Typography>
                )}

                {R.findIndex(R.propEq('Roles', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/roles')}>
                    Roles
                  </Typography>
                )}

                {R.findIndex(R.propEq('Users', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/users')}>
                    Users
                  </Typography>
                )}

                {![
                  R.findIndex(R.propEq('Holiday', 'screenName'))(
                    settingModule ? settingModule : [],
                  ),
                  R.findIndex(R.propEq('Import Leaves', 'screenName'))(
                    settingModule ? settingModule : [],
                  ),
                  R.findIndex(R.propEq('Leave Policy', 'screenName'))(
                    settingModule ? settingModule : [],
                  ),
                ].every((r) => r === -1) && (
                  <Typography
                    className='submenu-item'
                    aria-describedby={leaveId}
                    onClick={handleLeaveClick}>
                    Leaves
                  </Typography>
                )}

                {R.findIndex(R.propEq('Third Party API Keys', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && <Typography className='submenu-item'>Third Party API Keys</Typography>}

                {R.findIndex(R.propEq('Email Templates', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => gotoNavigate('/settings/email-template')}>
                    Email Templates
                  </Typography>
                )}
              </Typography>
            </Popover>
            <Popover
              className='submenu-pop'
              id={myPlanId}
              open={myPlanOpen}
              anchorEl={myPlananchorEl}
              onClose={myPlanHandleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <Typography component={'div'} className='submenu-box'>
                {R.findIndex(R.propEq('My Plan', 'moduleName'))(screenList ? screenList : []) !==
                  -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => {
                      setMyPlanAnchorEl(null);
                      gotoNavigate(AdminPrivateRoutes.MyPlan.MyPlanVIEW);
                    }}>
                    My Plan
                  </Typography>
                )}
                {R.findIndex(R.propEq('My Leaves', 'moduleName'))(screenList ? screenList : []) !==
                  -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() => {
                      setMyPlanAnchorEl(null);
                      gotoNavigate(AdminPrivateRoutes.MyLeave.ViewList);
                    }}>
                    Leaves
                  </Typography>
                )}
              </Typography>
            </Popover>
            <Popover
              className='submenu-pop'
              id={leaveId}
              open={leaveOpen1}
              anchorEl={leaveAnchorEl}
              onClose={handleLeaveClose1}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <Typography component={'div'} className='submenu-box'>
                {R.findIndex(R.propEq('Holiday', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() =>
                      gotoNavigate(AdminPrivateRoutes.SETTINGS.LEAVE.HOLIDAY.HOLIDAYLIST)
                    }>
                    Holidays
                  </Typography>
                )}
                {R.findIndex(R.propEq('Import Leaves', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() =>
                      gotoNavigate(AdminPrivateRoutes.SETTINGS.LEAVE.IMPORTLEAVE.IMPORTLEAVELIST)
                    }>
                    Import Leaves
                  </Typography>
                )}
                {R.findIndex(R.propEq('Leave Policy', 'screenName'))(
                  settingModule ? settingModule : [],
                ) !== -1 && (
                  <Typography
                    className='submenu-item'
                    onClick={() =>
                      gotoNavigate(AdminPrivateRoutes.SETTINGS.LEAVE.LEAVEPOLICY.LIST)
                    }>
                    Leave Policy
                  </Typography>
                )}
              </Typography>
            </Popover>
          </ListItem>
        </List>
        <Box className='admin-sidebar-footer'>
          <List>
            <ListItem sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                  cursor: 'auto',
                }}>
                <ListItemText
                  primary={'CT-Hiring'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                    cursor: 'auto',
                  }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (userDetail.roleId === 15) {
                      gotoNavigate(AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYLIST);
                    }
                  }}>
                  Ver {version}
                </ListItemIcon>
              </ListItemButton>
              {/* <Typography component={'div'}>
                  <Typography component={'div'} className="dark-light">
                      <Button className="active"><img src={Light} alt="light" /> <Typography className="p">Light</Typography></Button>
                      <Button><img src={Dark} alt="Dark" /><Typography className="p">Dark</Typography></Button>
                  </Typography>
                  </Typography> */}
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1 }} className={open ? 'btable' : 'stable'}>
        <DrawerHeader />
        {props.children}
        <Footer />
      </Box>
    </Box>
  );
};

export default AdminPrivateLayout;
